import axios from "axios";
import _ from 'lodash';
import { mobilizeiUsersService } from "../mobilizei/users.service";
import config from "../../utils/config";
import { apiFetcher as mobilizeiApiFetcher } from "../mobilizei/apiFetcher";

export const authIrrobaService = {
    validateCredentials,
    refreshAccessToken
};

function validateCredentials(username, password) {
    const url = `platforms/irroba/auth/validate`;

    const body = {
      username: username,
      password: password
    }

    return mobilizeiApiFetcher(url, { method: "POST", body: JSON.stringify(body) });
}

function refreshAccessToken(storeId) {
    return axios
        .post(
            `${config.MOBILIZEI_API_BASE_URL}/platforms/irroba/auth/refresh`,
            { storeId }
        )
        .then(() => {
            return mobilizeiUsersService.getSelf().then(userData => {
                return _.get(userData, 'store.accessToken', '');
            })
        })
}