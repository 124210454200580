import { nuvemshopService as nps } from './mobilizei/nuvemshop.service';
import { trayService as tps } from './mobilizei/tray.service';
import { productsService as wps } from './wix/products.service';
import { productsService as ips } from './irroba/products.service'
import { mapNuvemProduct, mapNuvemProductArrayWithVariants } from '../utils/mappers/nuvemshop';
import { mapTrayProduct, mapTrayProductArray } from '../utils/mappers/tray';
import { mapWixProductArrayWithVariants, mapWixProduct } from '../utils/mappers/wix'
import { mapIrrobaProductArray, mapIrrobaProduct } from '../utils/mappers/irroba'
import _ from 'lodash';
import { PLATFORMS } from '../utils/constants';

export const productsService = {
    getProducts,
    getProductById,
    searchProducts
};

function getProducts(platformId, storeId, options, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;

    switch(platformId){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.getProducts;
            mapperMethod = mapNuvemProductArrayWithVariants;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.getProducts;
            mapperMethod = mapTrayProductArray;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProducts;
            mapperMethod = mapWixProductArrayWithVariants;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProducts;
            mapperMethod = mapIrrobaProductArray
            break;
        default:
            break;
    }

    return queryMethod(storeId, options, accessToken).then(response => {
        let data = null;

        switch(platformId){
            case PLATFORMS.NUVEMSHOP:
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
                break;
            case PLATFORMS.TRAY:
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
                break;
            case PLATFORMS.WIX:
                data = {
                    products: mapperMethod(response.products),
                    totalCount: _.get(response, 'totalResults', 0)
                }
                break;
            case PLATFORMS.IRROBA:
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.pagination.nextPageUrl ? 100 : response.pagination.lastItem
                }
                break;
            default:
                break;
        }

        return data;
    })
}

function getProductById(platformId, storeId, productId, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;

    switch(platformId){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.getProductById;
            mapperMethod = mapNuvemProduct;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.getProductById;
            mapperMethod = mapTrayProduct;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProductById;
            mapperMethod = mapWixProduct;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProductById;
            mapperMethod = mapIrrobaProduct;
            break;
        default:
            break;
    }

    return queryMethod(storeId, productId, accessToken).then(response => {
        let data = null;

        switch(platformId){
            case PLATFORMS.WIX:
                data = mapperMethod(response.product);
                break;
            case PLATFORMS.IRROBA:
                if(response.data?.length > 0){
                    data = mapperMethod(response.data[0])
                }
                break;
            default:
                data = mapperMethod(response)
                break;
        }

        return data
    })
}

function searchProducts(platformId, storeId, options, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;

    switch(platformId){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.searchProducts;
            mapperMethod = mapNuvemProductArrayWithVariants;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.searchProducts;
            mapperMethod = mapTrayProductArray;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProducts;
            mapperMethod = mapWixProductArrayWithVariants;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProducts;
            mapperMethod = mapIrrobaProductArray;
            break;
        default:
            break;
    }

    return queryMethod(storeId, options, accessToken).then(response => {
        let data = null;

        switch(platformId){
            case PLATFORMS.NUVEMSHOP:
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
                break;
            case PLATFORMS.TRAY:
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
                break;
            case PLATFORMS.WIX:
                data = {
                    products: mapperMethod(response.products),
                    totalCount: _.get(response, 'totalResults', 0)
                }
                break;
            case PLATFORMS.IRROBA:
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.pagination.nextPageUrl ? 100 : response.pagination.lastItem
                }
                break;
            default:
                break;
        }

        return data;
    })
}