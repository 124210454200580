import { apiFetcher } from "./apiFetcher";
import _ from 'lodash'

export const categoriesService = {
  getAllCategories
};

function getAllCategories(storeId, page, categoriesArray, accessToken) {
  const url = `category`

  return apiFetcher(storeId, accessToken, url, { method: "GET" });
}