import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from '../../utils/config';
import image from '../../assets/img/integrationHelps/irrobaIntegrationHelp.png';
import { Box, CircularProgress, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { MbTextField } from '../MbTextFields';
import { useOnboarding } from '../../contexts/onboarding.context';
import { useUser } from '../../contexts/user.context';
import { authIrrobaService } from '../../services/irroba/auth.service';
import { showApiError } from '../../utils/errors';

export default function IrrobaIntegration() {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();

  const { data } = useOnboarding();

  const { handleSubmit, register, formState: { errors }, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      storeId: "",
      storeUrl: "",
      username: "",
      password: "",
    }
  });

  const onRedirect = (values) => {
    const url = `${config.MOBILIZEI_API_BASE_URL}/${config.IRROBA_AUTH_PATH}/?` +
      `username=${values.username}&` +
      `password=${values.password}&` +
      `email=${user.email}&` +
      `store_id=${values.storeId}&` +
      `store_name=${data.storeName}&` +
      `store_url=${values.storeUrl}`

    window.open(url, "_self");
    setIsLoading(false);
  }

  const validateCredentials = async (values) => {
    setIsLoading(true)
    return authIrrobaService.validateCredentials(values.username, values.password)
      .then(() => {
        onRedirect(values);
      }).catch(() => {
        showApiError({ message: "Credenciais de usuário de API inválidas." });
        setIsLoading(false);
      })
  }

  const platformData = {
    name: "Irroba",
    imgUrl: image,
    buttonText: "Aceitar e começar"
  }

  return (
    <Container maxWidth="lg" sx={{ paddingY: 4 }}>
      <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
        Integração com a {platformData.name}
      </Typography>

      <Box
        container
        sx={{ mt: 6, mb: 6 }}
      >
        <Typography variant="h6" align='center' lineHeight={1.5} mb={2}>
          Para continuar a criação do seu aplicativo, é necessário realizar a integração entre a sua loja {platformData.name} e a Mobilizei.
        </Typography>

        <Typography variant="h6" align='center' lineHeight={1.5} mb={2}>
          A plataforma {platformData.name} permite a criação de um usuário de API, é dessa forma que iremos nos conectar à sua loja.
        </Typography>

        <Typography variant="h6" align='center' lineHeight={1.5} mb={2}>
          Você pode fazer isso conforme descrito abaixo:
        </Typography>
      </Box>


      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box>
            <img src={platformData.imgUrl} alt='integração' style={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box mb={6} display={'flex'} justifyContent={'center'}>
              <Typography variant="h7" align='center' lineHeight={1.5}>
                Após criar o usuário de API, preencha as informações abaixo:
              </Typography>
            </Box>


            <Box mb={2}>
              <MbTextField
                error={errors.storeId?.message}
                placeholder="Insira o ID da sua loja"
                register={register("storeId", {
                  required: "O ID da loja é obrigatório."
                })}
              />
            </Box>

            <Box mb={2}>
              <MbTextField
                error={errors.storeUrl?.message}
                placeholder="Insira a URL da sua loja"
                register={register("storeUrl", {
                  required: "A URL da loja é obrigatória."
                })}
              />
            </Box>

            <Box mb={2}>
              <MbTextField
                error={errors.username?.message}
                placeholder="Insira o usuário de API criado"
                register={register("username", {
                  required: "O usuário de API é obrigatório."
                })}
              />
            </Box>

            <Box mb={2}>
              <MbTextField
                error={errors.password?.message}
                placeholder="Insira a senha do usuário de API criado"
                type="password"
                register={register("password", {
                  required: "A senha do usuário de API é obrigatória."
                })}
              />
            </Box>

            {isLoading ? (
              <Grid
                container
                justifyContent='center'
                sx={{ mt: 4, mb: 10 }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 4 }}
                  onClick={handleSubmit(validateCredentials)}
                >
                  Integrar
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}