import _ from "lodash";

const mapIrrobaCategory = category => {
  const description = category.category_description.find(x => x.language_id === 2);
  
  let mappedCategory = {
      id: category.category_id,
      name: _.get(description, 'name', ''),
      parentId: category.parent_id ? category.parent_id : 0,
  }

  return mappedCategory;
}

const mapCategoriesTree = (items, id = 0, link = 'parentId', parentName = '') => {
  items.sort((a,b) => { return a.name.localeCompare(b.name) });

  return items
      .filter(item => item[link] === id)
      .map(item => {
        const breadcrumb = 
          parentName === '' ?
          item.name :
          `${parentName} > ${item.name}`

        return { 
          ...item, 
          breadcrumb,
          subcategories: mapCategoriesTree(items, item.id, 'parentId', breadcrumb) 
        };
      });
}

export const mapIrrobaCategories = (categories) => {
  
  let mappedCategories = categories.reduce((result, category) => {
      result.push(mapIrrobaCategory(category));
      return result;
  }, []);

  return mapCategoriesTree(mappedCategories);
};

export const mapIrrobaProduct = product => {

  const description = product.product_description.find((x) => x.language_id === 2);

  const price = parseFloat(product.price);
  const oldPrice = parseFloat(product.price);

  if (!description) return null;

  let mappedProduct = {};

  mappedProduct["id"] = product.product_id;
  mappedProduct["name"] = _.get(description, 'name', '');
  mappedProduct["brand"] = String(product.manufacturer_id);
  mappedProduct["description"] = _.get(description, 'description', '');
  mappedProduct["url"] = product.url ? product.url : `${description.name}${product.model}`;
 

  // Price variables to help setting other properties. We are always going
  // to have at least one variant, so we get the price from the first one.
  const productPriceData = product.priceData;

  const irrobaPrice = isNaN(parseFloat(price))
    ? 0
    : parseFloat(price);
  const irrobaPromotionalPrice = isNaN(parseFloat(price))
    ? 0
    : parseFloat(price);

  // Set product prices
  mappedProduct["price"] = irrobaPrice;
  mappedProduct["oldPrice"] = irrobaPromotionalPrice;

  // Set product display prices
  mappedProduct["displayPrice"] = irrobaPrice.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
  mappedProduct["displayOldPrice"] = irrobaPrice.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  })

  // Set the discount percentage
  mappedProduct["discountPercentage"] = 0

  // Set product info booleans
  mappedProduct["hasPromotionalPrice"] = false;
  mappedProduct["hasVariant"] = false;

  // Set if we can show this product price
  mappedProduct["canShowPrice"] = true

  mappedProduct["stock"] = 999

  // Map product images and videos to array
  const productMedia = product.product_image;

  const images =
    productMedia && productMedia.length > 0
      ? productMedia.reduce((result, element) => {
        result.push({
          imageUrl: element.image
        })

        return result
      }, [])
      : [];
  
  mappedProduct["images"] =  [{ imageUrl: product.image }, ...images];

  return mappedProduct;
};

export const mapIrrobaProductItem = (product) => {
  let mappedProduct = mapIrrobaProduct(product);

  // Set product variants
  mappedProduct["variants"] = [];

  // Indicates if these product variants have different 
  // prices between any of them
  mappedProduct["hasDifferentVariantPrices"] = false;

  // Set product visibility
  mappedProduct["isVisible"] = product.status === 1 ? true : false;

  // Set product availability
  mappedProduct["isAvailable"] = true

  // Set variants options
  mappedProduct["variantOptions"] = [];

  return mappedProduct;
}

export const mapIrrobaProductArray = (data) => {
  let mappedProducts = []

  mappedProducts = data.map(product => mapIrrobaProductItem(product))
  mappedProducts = mappedProducts.filter(product => product.isVisible);

  return mappedProducts;
}