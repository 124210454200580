import { PLATFORMS } from '../utils/constants';
import { nuvemshopService as ncs } from './mobilizei/nuvemshop.service';
import { trayService as tcs } from './mobilizei/tray.service';
import { categoriesService as wcs } from './wix/categories.service';
import { categoriesService as ics } from './irroba/categories.service';
import { mapNuvemCategories } from '../utils/mappers/nuvemshop';
import { mapTrayCategories } from '../utils/mappers/tray';
import { mapWixCategories } from '../utils/mappers/wix';
import { mapIrrobaCategories } from '../utils/mappers/irroba'

export const categoriesServices = {
    getAllCategories
};

function getAllCategories(platformId, storeId, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;

    switch(platformId){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = ncs.getAllCategories;
            mapperMethod = mapNuvemCategories;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tcs.getAllCategories;
            mapperMethod = mapTrayCategories;
            break;
        case PLATFORMS.WIX:
            queryMethod = wcs.getAllCategories;
            mapperMethod = mapWixCategories;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ics.getAllCategories;
            mapperMethod = mapIrrobaCategories
            break;
        default:
            break;
    }

    return queryMethod(storeId, 1, [], accessToken).then(response => {
        const categories = platformId === PLATFORMS.IRROBA 
            ? mapperMethod(response.data) : mapperMethod(response);
        return categories;
    })
}