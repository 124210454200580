import { useEffect } from "react";
import AppLoading from '../components/AppLoading';
import { BillingRouter, PrivateAppRouter, PublicAppRouter } from '../routers/AppRouter'
import { useUser } from '../contexts/user.context';
import { useOnboarding } from "../contexts/onboarding.context";
import useAuthentication from '../hooks/authentication.hook';
import { storeStatus } from '../utils/constants';

export default function AppAuthentication() {
    const { isAuthLoading } = useAuthentication()
    const { user } = useUser()
    const { setData } = useOnboarding();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)

        const allowsIframe = searchParams.get("iframe")
        const platformId = searchParams.get("platform_id")
        const platformName = searchParams.get("platform_name")
        const storeId = searchParams.get("store_id")
        const storeName = searchParams.get("store_name")
        const storeUrl = searchParams.get("store_url")
        const platformToken = searchParams.get("platform_token")
        // Platform refresh token and site id exists only wix platform
        const platformRefreshToken = searchParams.get("platform_refresh_token")
        const siteId = searchParams.get("site_id")
        // storeApiUsername and storeApiPassword exists only irroba platform
        const storeApiUsername = searchParams.get("store_api_username");
        const storeApiPassword = searchParams.get("store_api_password");

        if (storeId && storeUrl && platformId && platformToken) {
            const onboardingData = {
                ecommercePlatformAllowsIframe: allowsIframe === '1',
                ecommercePlatformId: platformId,
                ecommercePlatformName: platformName,
                ecommercePlatformToken: platformToken,
                ecommercePlatformRefreshToken: platformRefreshToken,
                storeId: storeId,
                storeName: storeName,
                storeUrl: storeUrl,
                siteId: siteId,
                storeApiUsername: storeApiUsername,
                storeApiPassword: storeApiPassword
            }

            setData(onboardingData)
        }
    }, [])

    if (isAuthLoading)
        return <AppLoading />

    if (!user)
        return <PublicAppRouter />

    // If the onboarding is completed, allow the panel to load only 
    // if the store status is on_trial or active
    if (
        user?.isOnboardingCompleted &&
        user?.store &&
        ![storeStatus.ON_TRIAL, storeStatus.ACTIVE].includes(user?.store?.status)
    ) {
        return <BillingRouter />
    }

    return <PrivateAppRouter />
}